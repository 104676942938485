<template>
  <div class="app-wrapper">
    <div class="menu-container" @mouseleave="hideChildren">
      <div class="top-menu">
        <div class="menu-block">
          <div class="logo-container">
            <router-link class="logo-link" to="/">
              <img src="@/assets/yf/logo.png" class="logo" alt="logo">
            </router-link>
          </div>

          <div class="menu">
            <div class="menu-item" v-for="(menu, index) in routers" :key="index">
              <div class="menu-level-1" :class="{ 'active': activeMenu.parent === menu.name }" v-if="menu.redirect&&!menu.hidden" @click="visit(menu)" @mouseover="showChildren(index)">
                {{ menu.title }}
              </div>

              <div class="menu-level-1" :class="{ 'active': activeMenu.parent === menu.name }" v-if="!menu.redirect&&!menu.hidden" @mouseover="showChildren(index)">
                {{ menu.title }}
              </div>
            </div>
            <div class="menu-level-search" @mouseover="showChildren(-1)"><svg-icon class="icon_search" icon-class="icon_search" /></div>
          </div>
        </div>
      </div>
      <div v-show="children.length > 0" class="children-menu">
        <div v-for="(item, index) in children" :key="index" class="menu-level-2" :class="{ 'activeChildren': (activeMenu.fullPath === item.path || activeMenu.child === item.name) }" @click="visit(item)">{{ item.meta.title }}</div>
      </div>
      <div v-show="search" class="children-menu">
        <el-col :span="6">
          <el-input
            placeholder="请输入搜索内容"
            suffix-icon="el-icon-search"
            v-model="keywords">
          </el-input>
        </el-col>
      </div>
    </div>

    <router-view/>

    <right-float-buttons :key="componentKey" :custom-style="myBackToTopStyle" :visible="visible" :back-position="0" transition-name="fade" />

    <div class="footer">
      <div class="footer-container">
        <div class="min-size">
          <div class="col1">
            <div class="title">关于我们</div>
            <div class="content">
              <div>公司简介</div>
              <div>公司文化</div>
              <div>公司动态</div>
            </div>
          </div>
          <div class="col2">
            <div class="title">联系我们</div>
            <div class="content">
              <div>联系电话: 0731-85115328</div>
              <div>电子邮箱: HN_yinfang@163.com</div>
              <div>联系地址: 湖南省长沙市高新区麓天路15号银河科技园3楼301室</div>
            </div>
          </div>
          <div class="col3">
            <div class="title">关注我们</div>
            <img src="@/assets/yf/qrcode_gzh.jpg" class="qr" alt="qr">
            <div class="qr-info">长沙农金服平台微信公众号</div>
          </div>
        </div>
      </div>
      <div class="copy-right">版权信息 ：Copyright © 2021 湖南银方信息技术有限公司 | <a href = "https://beian.miit.gov.cn">湘ICP备2020018598号</a></div>
    </div>
  </div>
</template>

<script>
import RightFloatButtons from '@/components/RightFloatButtons'

export default {
  name: 'Layout',
  components: { RightFloatButtons },
  data () {
    return {
      routers: [],
      children: [],
      search: false,
      keywords: '',
      childrenHover: false,
      myBackToTopStyle: {
        // right: '100px',
        bottom: '100px',
        width: '60px',
        height: '192px'
      },
      componentKey: 0,
      visibilityHeight: 300,
      visible: false
    }
  },
  computed: {
    activeMenu () {
      const route = this.$route

      const activeObj = {
        parent: '',
        child: '',
        fullPath: route.fullPath
      }

      const { meta, path, name } = route
      if (meta.activeMenuName) {
        activeObj.parent = meta.activeMenuName
      } else {
        activeObj.parent = path
      }

      if (name) {
        activeObj.child = name
      }

      return activeObj
    }
  },
  mounted () {
  },
  created () {
    window.addEventListener('resize', this.GetWindowInfo) // 注册监听器
    window.addEventListener('scroll', this.handleScroll)
    this.GetWindowInfo() // 页面创建时先调用一次
    this.handleScroll()
    this.getAllRoute()
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.GetWindowInfo)
  },
  methods: {
    handleScroll () {
      this.visible = window.pageYOffset > this.visibilityHeight
    },
    GetWindowInfo () {
      const width = window.innerWidth // 宽
      // const height = window.innerHeight // 高
      if (width > 1440) {
        delete this.myBackToTopStyle.right
        const left = (width + 1200) / 2 + 60
        this.myBackToTopStyle.left = left + 'px'
      } else {
        delete this.myBackToTopStyle.left
        this.myBackToTopStyle.right = '60px'
      }
      this.componentKey += 1
    },
    getAllRoute () {
      const items = this.$router.options.routes

      this.routers = items.map(v => {
        let title = ''
        let subMenu = false
        if (v.meta && v.meta.title) {
          title = v.meta.title
        } else {
          if (v.name) {
            title = v.name
          } else {
            title = v.path
          }
        }

        const children = []
        for (let i = 0; i < v.children.length; i++) {
          if (!v.children[i].hidden) {
            children.push(v.children[i])
          }
        }

        v.children = children

        if (v.children.length > 1) {
          subMenu = true
        }

        this.$set(v, 'subMenu', subMenu)
        this.$set(v, 'title', title)
        return v
      })
    },
    visit (menu) {
      if (menu.name) {
        this.$router.push({
          name: menu.name,
          params: {}
        }).catch(err => err)
      } else {
        // 针对锚点
        if (menu.meta.type === 'anchor') {
          this.$router.push(menu.path).catch(err => err)
        }
      }
    },
    showChildren (index) {
      if (index >= 0) {
        this.search = false
        // 菜单聚焦
        if (this.routers[index].children.length > 1) {
          this.children = this.routers[index].children
        } else {
          this.children = []
        }
      } else {
        this.children = []
        this.search = true
      }
    },
    hideChildren () {
      this.children = []
      this.search = false
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  min-width: 1200px;
}

.menu-container {
  background: rgba(0,0,0,0.6);
  width: 100%;
  min-width: 1200px;
  position: fixed;
  z-index: 10;

  .top-menu {
    height: 88px;
    display: flex;
    justify-content: center;

    .menu-block {
      height: 100%;
      width: 1200px;
      display: flex;

      .logo-container {
        width: 190px;
        height: 88px;

        img.logo {
          width: 100%;
          height: 100%;
        }
      }

      .menu {
        margin-left: 60px;
        font-size: 18px;
        display: flex;

        .menu-item {
          display: flex;
        }

        .menu-level-1 {
          width: 142px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          cursor: pointer;

          &.active {
            border-bottom: 3px solid #c00200;
            font-weight: bold;
          }

          &:hover {
            border-bottom: 3px solid #c00200;
            font-weight: bold;
          }
        }

        .menu-level-search {
          margin-left: 35px;
          height: 88px;
          display: flex;
          align-items: center;

          .icon_search {
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .children-menu {
    border-top: 1px solid rgba(255,255,255,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 88px;
    width: 100%;
    height: 88px;
    min-width: 1200px;
    font-size: 18px;

    .el-icon-search {
      font-size: 24px;
    }

    .menu-level-2 {
      display: flex;
      align-items: center;
      color: #ffffff;
      cursor: pointer;

      &.activeChildren {
        font-weight: bold;
      }

      &:not(:first-child) {
        margin-left: 70px;
      }
    }
  }
}

.footer {
  height: 338px;
  background: #010103;
  display: flex;
  flex-flow: column nowrap;

  .footer-container {
    height: 266px;
    border-bottom: 1px solid rgba(255,255,255,0.3);

    display: flex;
    justify-content: center;

    .min-size {
      width: 1200px;
      display: flex;
      padding: 35px 0;

      .col1 {
        padding-left: 107px;
        width: 192px;
      }

      .col2 {
        flex-grow: 1;
      }

      .col3 {
        width: 149px;
        padding-right: 117px;
        text-align: center;
        .title {
          text-align: center;
        }

        .qr{
          width: 110px;
        }

        .qr-info {
          font-size: 12px;
          color: #cccccc;
          line-height: 40px;
        }
      }

      .title {
        font-size: 16px;
        color: #ffffff;
        font-weight: 700;
        line-height: 40px;
        padding-bottom: 10px;
      }

      .content {
        font-size: 14px;
        color: #cccccc;
        line-height: 40px;
      }
    }
  }

  .copy-right {
    color: #cccccc;
    font-size: 14px;
    line-height: 40px;
    flex-grow: 1;
    padding-top: 12px;
    text-align: center;
  }
}
</style>
