import Vue from 'vue'
import VueRouter from 'vue-router'

/* Layout */
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: '首页' },
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: 'index',
        meta: { activeMenuName: 'Home' },
        component: () => import('@/views/home/index.vue')
      }
    ]
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { title: '公司简介' },
    component: Layout,
    redirect: '/profile',
    children: [
      {
        path: '',
        meta: { activeMenuName: 'Profile' },
        component: () => import('@/views/profile/index.vue')
      }
    ]
  },
  {
    path: '/zhaomu',
    name: 'zhaomu',
    meta: { title: '招募' },
    component: Layout,
    redirect: '/zhaomu',
    hidden:true,
    children: [
      {
        path: '',
        meta: { activeMenuName: 'zhaomu' },
        component: () => import('@/views/zhaomu/index.vue')
      }
    ]
  },
  // {
  //   path: '/customer',
  //   name: 'Customer',
  //   meta: { title: '合作伙伴' },
  //   component: Layout,
  //   redirect: '/customer/partner',
  //   children: [
  //     {
  //       path: 'partner',
  //       name: 'Partner',
  //       meta: { title: '合作伙伴', activeMenuName: 'Customer' },
  //       component: () => import('@/views/customer/partner.vue')
  //     },
  //     // {
  //     //   path: 'introduction',
  //     //   name: 'Introduction',
  //     //   meta: { title: '客户简介', activeMenuName: 'Customer' },
  //     //   component: () => import('@/views/customer/introduction.vue')
  //     // },
  //     // {
  //     //   path: 'story',
  //     //   name: 'Story',
  //     //   meta: { title: '客户故事', activeMenuName: 'Customer' },
  //     //   component: () => import('@/views/customer/story.vue')
  //     // },
  //     // {
  //     //   path: 'story/view/:id',
  //     //   name: 'ViewStory',
  //     //   meta: { activeMenuName: 'Customer' },
  //     //   component: () => import('@/views/customer/view.vue'),
  //     //   hidden: true
  //     // }
  //   ]
  // },
  {
    path: '/service',
    name: 'Service',
    meta: { title: '公司业务' },
    component: Layout,
    redirect: '/service',
    children: [
      {
        path: '',
        meta: { activeMenuName: 'Service' },
        component: () => import('@/views/service/index.vue'),
        hidden: true
      },
      // {
      //   path: '/service#operation',
      //   meta: { title: '平台运营逻辑', activeMenuName: 'Service', type: 'anchor' }
      // },
      // {
      //   path: '/service#management',
      //   meta: { title: '智能风控', activeMenuName: 'Service', type: 'anchor' }
      // },
      // {
      //   path: '/service#services',
      //   meta: { title: '平台服务', activeMenuName: 'Service', type: 'anchor' }
      // },
      // {
      //   path: '/service#development',
      //   meta: { title: '平台产品', activeMenuName: 'Service', type: 'anchor' }
      // }
    ]
  },
  {
    path: '/news',
    name: 'News',
    meta: { title: '新闻动态' },
    component: Layout,
    redirect: '/news',
    children: [
      {
        path: '',
        meta: { activeMenuName: 'News' },
        component: () => import('@/views/news/index.vue')
      },
      {
        path: 'view/:id',
        name: 'ViewNews',
        meta: { activeMenuName: 'News' },
        component: () => import('@/views/news/view.vue'),
        hidden: true
      }
    ]
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { title: '联系我们' },
    component: Layout,
    redirect: '/contact',
    children: [
      {
        path: '',
        meta: { activeMenuName: 'Contact' },
        component: () => import('@/views/contact/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 如果你的連結是帶 # 這種
    // to.hash 就會有值(值就是連結)
    // 例如 #3
    if (to.hash) {
      return {
        // 這個是透過 to.hash 的值來找到對應的元素
        // 照你的 html 來看是不用多加處理這樣就可以了
        // 例如你按下 #3 的連結，就會變成 querySelector('#3')，自然會找到 id = 3 的元素
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
