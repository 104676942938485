<template>
  <transition :name="transitionName">
    <div :style="customStyle" class="back-to-ceiling">
      <!-- <div class="block" @mouseenter="changeSvg('wechat', '金融平台')" @mouseleave="changeSvg('wechat', '')">
        <div v-if="block === 'wechat'" class="block-text">{{ text }}</div>
        <svg-icon v-else class="wechat" icon-class="Right-Wechat" />
      </div>
      <div class="block" @mouseenter="changeSvg('contact', '联系我们')" @mouseleave="changeSvg('contact', '')">
        <div v-if="block === 'contact'" class="block-text">{{ text }}</div>
        <svg-icon v-else class="contact" icon-class="Right-Contact" />
      </div> -->
      <div v-show="visible" class="block" @mouseenter="changeSvg('back-to-top', '返回顶部')" @mouseleave="changeSvg('contact', '')" @click="backToTop">
        <div v-if="block === 'back-to-top'" class="block-text">{{ text }}</div>
        <div v-else class="back-to-top">
          <svg-icon class="back-icon" icon-class="Right-To-Top" />
          <div class="text">TOP</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'RightFloatButtons',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    backPosition: {
      type: Number,
      default: 0
    },
    customStyle: {
      type: Object,
      default: function () {
        return {
          right: '50px',
          bottom: '50px',
          width: '40px',
          height: '40px'
        }
      }
    },
    transitionName: {
      type: String,
      default: 'fade'
    }
  },
  data () {
    return {
      interval: null,
      isMoving: false,
      block: '',
      text: ''
    }
  },
  mounted () {
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    changeSvg (block, text) {
      if (text) {
        this.block = block
        this.text = text
      } else {
        this.block = ''
      }
    },
    backToTop () {
      if (this.isMoving) return
      const start = window.pageYOffset
      let i = 0
      this.isMoving = true
      this.interval = setInterval(() => {
        const next = Math.floor(this.easeInOutQuad(10 * i, start, -start, 500))

        if (next <= this.backPosition) {
          window.scrollTo(0, this.backPosition)
          clearInterval(this.interval)
          this.isMoving = false
        } else {
          window.scrollTo(0, next)
        }
        i++
      }, 16.7)
    },
    easeInOutQuad (t, b, c, d) {
      if ((t /= d / 2) < 1) return c / 2 * t * t + b
      return -c / 2 * (--t * (t - 2) - 1) + b
    }
  }
}
</script>

<style lang="less" scoped>
.back-to-ceiling {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 5;

  .block {
    &:not(:first-child) {
      margin-top: 6px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}

.block {
  width: 60px;
  height: 60px;
  opacity: 1;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .wechat, .contact {
    font-size: 38px;
  }

  .back-to-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .back-icon{
      font-size: 28px;
    }

    .text {
      font-weight: 700;
      font-size: 16px;
      color: #c00000;
    }
  }

  .block-text {
    width: 28px;
    font-size: 14px;
    color: #ffffff;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
  }

  &:hover {
    background: #c00000;
  }
}
</style>
